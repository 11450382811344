import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../context/AuthProvider';
import { validateEmail } from '../../helpers/general';
import { bcApi } from '../../helpers/bigcommerce';
import { getListsByEmail, subscribe, unsubscribe } from '../../helpers/klaviyo';

import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import Button from '../../components/atoms/Button/Button';
import styles from './account.module.css';
import Loader from '../../components/atoms/Loader/Loader';
import Dialog from '../../components/atoms/Dialog/Dialog';

const Account = () => {
  const auth = useContext(AuthContext);
  const checkPassword = auth && auth.checkPassword;
  const requiredFields = {
    first_name: 'Name',
    last_name: 'Surname',
    email: 'Email'
  };
  const defaultFields = {
    first_name: '',
    last_name: '',
    phone: '',
    company: '',
    email: '',
    current_password: '',
    new_password: '',
    confirm_password: ''
  };
  const customFields = {
    'Company ABN': ''
  };
  const [accountDetails, setAccountDetails] = useState(defaultFields);
  const [customDetails, setCustomDetails] = useState(customFields);
  const [fieldErrors, setFieldErrors] = useState({});
  const [editing, toggleEditing] = useState(false);
  const [defaultSet, toggleDefaultSet] = useState(false);
  const [emailLists, setEmailLists] = useState([]);
  const [fetchingLists, setFetchingLists] = useState(false);
  const [subscribed, setSubscribed] = useState({});
  const [dialogMessage, setDialogMessage] = useState(false);

  useEffect(() => {
    // console.log(emailLists, 'emailLists');
    if (!defaultSet && auth && 'state' in auth && 'object' in auth.state) {
      setAccountDetails({
        ...accountDetails,
        ...{
          first_name: auth.state.object.first_name,
          last_name: auth.state.object.last_name,
          phone: auth.state.object.phone,
          company: auth.state.object.company,
          email: auth.state.object.email
        }
      });

      const companyAbnValue = auth.state.object.form_fields?.filter(
        formField => formField.name === 'Company ABN'
      );
      setCustomDetails({
        ...customDetails,
        ...{
          'Company ABN':
            companyAbnValue && companyAbnValue.length > 0
              ? companyAbnValue[0].value
              : ''
        }
      });
      toggleDefaultSet(true);
    }
  }, [auth, accountDetails, customDetails, defaultSet, toggleDefaultSet]);

  useEffect(() => {
    // console.log(accountDetails, 'Account Details');
    if (accountDetails.email && !fetchingLists) {
      setFetchingLists(true);
      getListsByEmail(accountDetails.email)
        .then(response => {
          // console.log(response, 'list');
          const formattedLists = response.map(list => ({
            listId: list.id,
            listName: list.attributes.name,
            subscribed: false
          }));
          setEmailLists(formattedLists);
          setFetchingLists(false);
        })
        .catch(error => {
          console.error('Error fetching lists:', error);
          setFetchingLists(false);
        });
    }
  }, [accountDetails.email]);

  const toggleSubscription = async (e, listId, email, phone) => {
    const checked = e.target.checked;
    const parentElement = e.target.parentNode;
    parentElement.classList.add(styles.loading);

    try {
      if (checked) {
        await subscribe(listId, email, phone);
        setDialogMessage(
          'You are now subscribed. Please check your email for confirmation.'
        );
      } else {
        await unsubscribe(listId, email, phone);
        setDialogMessage('You have been unsubscribed.');
      }

      setSubscribed(prevSubscribed => ({
        ...prevSubscribed,
        [listId]: checked
      }));
    } catch (error) {
      console.error('Subscription error:', error);
      setDialogMessage('An error occurred. Please try again.');
    } finally {
      parentElement.classList.remove(styles.loading);
    }
  };

  const clearDialog = () => {
    setDialogMessage(false);
  };
  const saveDetails = async () => {
    const promises = [];
    let valid = true;
    const errors = {};
    setFieldErrors({});

    // Check if required fields are not blank
    Object.keys(requiredFields).map(field => {
      if (accountDetails[field] === '') {
        valid = false;
        errors[field] = `${requiredFields[field]} can not be blank`;
      }

      return true;
    });

    // Validate the email address
    if (!validateEmail(accountDetails.email)) {
      valid = false;
      errors.email = 'This email address is invalid';
    }

    // Check if password is set to change and matches
    if (accountDetails.new_password !== '') {
      if (accountDetails.new_password !== accountDetails.confirm_password) {
        valid = false;
        errors.new_password = `Passwords do not match`;
      }

      if (accountDetails.current_password === '') {
        valid = false;
        errors.current_password = `Current password is required to set new password`;
      }

      if (accountDetails.current_password !== '') {
        promises.push(
          new Promise(res => {
            checkPassword(
              auth.state.object.email,
              accountDetails.current_password
            ).then(response => {
              if ('errors' in response) {
                valid = false;
                errors.current_password = `Current password is not correct`;
              }
              res(true);
              return true;
            });
          })
        );
      }
    }

    Promise.all(promises).then(() => {
      if (valid) {
        // Run process to save details
        const fields = accountDetails;
        if (accountDetails.new_password !== '') {
          fields.authentication = {
            new_password: accountDetails.new_password
          };
        }
        delete fields.current_password;
        delete fields.new_password;
        delete fields.confirm_password;
        fields.id = auth.state.customerId;

        // Handle custom fields
        const cFields = Object.keys(customDetails).map(customField => {
          return {
            name: customField,
            value: customDetails[customField],
            customer_id: auth.state.customerId
          };
        });

        bcApi('customers', 'PUT', [fields]).then(response => {
          setAccountDetails({ ...defaultFields, ...fields });
          bcApi('customers/form-field-values', 'PUT', cFields).then(
            response => {
              toggleEditing(!editing);
            }
          );
        });
      } else {
        setFieldErrors(errors);
      }
    });
  };

  const itemClasses = editing
    ? `${styles.details} ${styles.edit}`
    : `${styles.details} ${styles.view}`;
  const inputClasses = `formField mb-0 ${!editing ? 'hidden' : 'show'}`;
  const labelClasses = editing ? 'hidden' : `show ${styles.label}`;

  return (
    <div>
      <div className={`${styles.container}`}>
        <div>
          <p className="subtitle">&nbsp;</p>
          <div className={itemClasses}>
            <span>Name:</span>
            <div>
              <div className={labelClasses}>{accountDetails.first_name}</div>
              <div className={inputClasses}>
                <input
                  type="text"
                  name="name"
                  defaultValue={accountDetails.first_name}
                  onChange={e =>
                    setAccountDetails({
                      ...accountDetails,
                      first_name: e.target.value
                    })
                  }
                />
                {fieldErrors && 'first_name' in fieldErrors && (
                  <span className="error">{fieldErrors.first_name}</span>
                )}
              </div>
            </div>
          </div>
          <div className={itemClasses}>
            <span>Surname:</span>
            <div>
              <div className={labelClasses}>{accountDetails.last_name}</div>
              <div className={inputClasses}>
                <input
                  name="surname"
                  type="text"
                  defaultValue={accountDetails.last_name}
                  onChange={e =>
                    setAccountDetails({
                      ...accountDetails,
                      last_name: e.target.value
                    })
                  }
                />
                {fieldErrors && 'last_name' in fieldErrors && (
                  <span className="error">{fieldErrors.last_name}</span>
                )}
              </div>
            </div>
          </div>
          {(accountDetails.phone || editing) && (
            <div className={itemClasses}>
              <span>Phone:</span>
              <div>
                <div className={labelClasses}>{accountDetails.phone}</div>
                <div className={inputClasses}>
                  <input
                    type="text"
                    name="phone"
                    defaultValue={accountDetails.phone}
                    onChange={e =>
                      setAccountDetails({
                        ...accountDetails,
                        phone: e.target.value
                      })
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <p className="subtitle">Log in details</p>
          <div className={itemClasses}>
            <span>Email:</span>
            <div>
              <div className={labelClasses}>{accountDetails.email}</div>
              <div className={inputClasses}>
                <input
                  type="email"
                  defaultValue={accountDetails.email}
                  onChange={e =>
                    setAccountDetails({
                      ...accountDetails,
                      email: e.target.value
                    })
                  }
                />
                {fieldErrors && 'email' in fieldErrors && (
                  <span className="error">{fieldErrors.email}</span>
                )}
              </div>
            </div>
          </div>
          <div className={`${itemClasses} ${styles.password}`}>
            <span>Password:</span>
            <div>
              <div className={labelClasses}>********</div>
              <div className={!editing ? 'hidden' : 'show'}>
                <div className="formField">
                  <label htmlFor="newPassword">New Password</label>
                  <input
                    type="password"
                    id="newPassword"
                    autoComplete="new-password"
                    onChange={e =>
                      setAccountDetails({
                        ...accountDetails,
                        new_password: e.target.value
                      })
                    }
                  />
                  {fieldErrors && 'new_password' in fieldErrors && (
                    <span className="error">{fieldErrors.new_password}</span>
                  )}
                </div>
                <div className="formField">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    onChange={e =>
                      setAccountDetails({
                        ...accountDetails,
                        confirm_password: e.target.value
                      })
                    }
                  />
                  {fieldErrors && 'new_password' in fieldErrors && (
                    <span className="error">{fieldErrors.new_password}</span>
                  )}
                </div>
                <div
                  className={`formField ${
                    accountDetails.new_password === '' ? 'hidden' : 'show'
                  }`}
                >
                  <label htmlFor="currentPassword">Current Password</label>
                  <input
                    type="password"
                    id="currentPassword"
                    onChange={e =>
                      setAccountDetails({
                        ...accountDetails,
                        current_password: e.target.value
                      })
                    }
                  />
                  {fieldErrors && 'current_password' in fieldErrors && (
                    <span className="error">
                      {fieldErrors.current_password}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div className={styles.emailPreferences}>
            <p className="subtitle">Email Preferences</p>
            {fetchingLists && <p>Loading lists...</p>}
            {emailLists && emailLists.length > 0 ? (
              <ul className={styles.emailLists}>
                {emailLists.map((list, index) => (
                  <li key={index}>
                    <div className={styles.emailCheckbox}>
                      <input
                        type="checkbox"
                        id={`list-${list.listId}`}
                        checked={subscribed[list.listId] || false}
                        onChange={e =>
                          toggleSubscription(
                            e,
                            list.listId,
                            accountDetails.email,
                            accountDetails.phone
                          )
                        }
                      />
                      <label htmlFor={`list-${list.listId}`}>
                        {list.listName}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No lists available to display.</p>
            )}
          </div> */}
        </div>
      </div>
      <div className={styles.btnGroup}>
        <Button
          type="span"
          level="primary"
          onClick={
            !editing ? () => toggleEditing(!editing) : () => saveDetails()
          }
        >
          {!editing ? 'Update details' : 'Save details'}
        </Button>
        <Button
          type="span"
          level="secondary"
          className={!editing ? 'hidden' : 'show'}
          onClick={() => toggleEditing(!editing)}
        >
          Cancel update
        </Button>
      </div>
    </div>
  );
};

const AccountOuput = () => (
  <AccountPageWrapper metaTitle="Account - My details" title="My Details">
    <Account />
  </AccountPageWrapper>
);

export default AccountOuput;
